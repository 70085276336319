<template>
  <div class="VideoHeadline">
    <el-row type="flex" justify="center">
      <el-col :span="24">
        <div class="title"><span>{{ title }}</span></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.VideoHeadline {
  margin-top: .875rem;
  .title {
    position: relative;
    overflow: hidden;
    span{
      font-size: 1.5rem;
      font-weight: bold;
      color: #212121;
      line-height: 3rem;
      height: 3rem;
      padding: 0 0.9rem 0 0.3125rem;
      border-bottom: 5px solid #2181b0;
      display: inline-block;
      position: relative;

      &::after {
      content: '';
      background: url('../assets/image/sk05_icon_bg.png') no-repeat center center #fff;
      height: 5px;
      width: 10px;
      position: absolute;
      right: 0;
      bottom: -5px;
      z-index: 9;
    }
  }
  &::after {
    content: '';
    height: 1px;
    background-color: #2181b0;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}
  .line {
    .left {
      height: 0rem;
      width: 6rem;
      border-bottom: .3125rem solid #2181b0;
      border-right: .3125rem solid transparent;
      border-left: .3125rem solid #2181b0;
      display: inline-block;
    }
    .right {
      width: calc(100% - 8rem);
      border-bottom: 1px solid #2181b0;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}
</style>