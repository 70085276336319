<template>
  <div class="correlation">
    <el-row type="flex" justify="center">
      <el-col :xs="22" :sm="22" :md="20" :lg="23" :xl="23">
        <div
          class="correlation_pc"
          v-if="this.equipment == 'phone' ? false : true"
        >
          <Swiper :options="swiperOption">
            <SwiperSlide v-for="item in childrenVideos" :key="item.index">
              <div class="card" @click="shipath(item)">
                <div class="coverWrap">
                  <img  v-lazy="item.coverPath" alt="" class="cover" />
                  <img src="@/assets/image/zixun/播放.png" alt="" class="pause"/>
                </div>
                
                <!-- <div class="duration">
                  <p>{{ item.SYS_TOPIC }}</p>
                </div> -->
                
                <div class="card_content">
                  <p class="title">{{ item.SYS_TOPIC }}</p>
                  <!-- <p class="time">{{ $ModuleDate(item.doc.created) }}</p> -->
                </div>
              </div>
            </SwiperSlide>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </Swiper>

          <div class="arrows" v-if="childrenVideos.length>4">
            <img
              src="@/assets/image/home/图层 16.png"
              alt=""
              class="coPC-button-prev"
            />
            <img
              src="@/assets/image/home/deactive.png"
              alt=""
              class="coPC-button-next"
            />
          </div>
        </div>

        <div
          class="correlation_phone"
          v-if="this.equipment == 'phone'"
        >
          <Swiper :options="swiperOption_phone">
            <SwiperSlide v-for="item in childrenVideos" :key="item.index">
              <div class="card" @click="shipath(item)">
                <div class="coverWrap">
                  <img  v-lazy="item.coverPath" alt="" class="cover" />
                  <img src="@/assets/image/zixun/播放.png" alt="" class="pause"/>
                </div>
                <!-- <div class="duration">
                  <p>{{ item.duration }}</p>
                </div> -->
                <div class="card_content">
                  <p class="title" ref="titlee">{{ item.SYS_TOPIC }}</p>
                  <!-- <p class="time">{{ $ModuleDate(item.doc.created) }}</p> -->
                </div>
              </div>
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination"></div>
          </Swiper>

          <div class="arrows" v-if="childrenVideos.length>4">
            <img
              src="@/assets/image/home/图层 16.png"
              alt=""
              class="coPhone-button-prev"
            />
            <img
              src="@/assets/image/home/图层 16.png"
              alt=""
              class="coPhone-button-next"
              style="transform: rotateZ(180deg);"
            />
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 相关视频内容 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "",

  components: {
    Swiper,
    SwiperSlide,
  },
  props:['childrenVideos'],
  data () {
    return {
      show: '',
      // dongtaix: 'true',
      list: [
      ],
      swiperOption: {
        initialSlide: 0,
        loop: false,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 4,
        slidesPerGroup: this.$store.state.smallPc ? 2 : 4,
        spaceBetween: 20,
        navigation: {
          nextEl: ".coPC-button-next",
          prevEl: ".coPC-button-prev",
        },
      },
      swiperOption_phone: {
        initialSlide: 0,
        loop: false,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".coPhone-button-next",
          prevEl: ".coPhone-button-prev",
        },
      },
      equipment: this.$store.state.isEquipment,
    };
  },
  methods: {
    shipath (n) {
      this.$emit('child-event', n);
      // api.get(`spc/prodb/getPortalResource.do?doclibId=${n.docLibID}&docID=${n.docID}`, { //单条详情数据
      // }).then(res => {
      //   this.$emit('child-event', res.data);
      //   // this.$emit('child-two', res.data);
      // })
      document.documentElement.scrollTop = 0;//回到顶部
    }
  },
};
</script>

<style lang='scss' scoped>
.correlation {
  // position: relative;

  .card {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
    position: relative;
    cursor: pointer;
    .coverWrap{
      position: relative;
      padding-bottom: 75%;
      width:100%;
    }
    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
    }


    .pause {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      width:30%;
    }

    .card_content {
      padding: 1.25rem 1.125rem 1rem 1.125rem;

      .title {
        font-size: 1.125rem;
        font-weight: 500;
        color: #212121;
        line-height: 1.6875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        font-size: 1rem;
        font-weight: 400;
        color: #c29460;
        line-height: 3rem;
      }
    }
  }

  .correlation_pc {
    position: relative;

    .arrows {
      position: absolute;
      top: 50%;
      width: 100%;

      img {
        width: 2.5rem;
        height: 2.5rem;
        // z-index: 999;
        cursor: pointer;
      }

      .coPC-button-prev {
        margin-left: -4rem;
      }

      .coPC-button-next {
        margin-right: -4rem;
        float: right;
      }
    }
  }

  .correlation_phone {
    padding-bottom: 3.75rem;

    .arrows {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;

      img {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 10px;
      }

      .swiper-button-prev {
        left: -4rem;
      }

      .swiper-button-next {
        right: 3.5rem;
      }
    }
  }
}
</style>