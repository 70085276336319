<template>
  <div class="Details" :class="{ phone: $store.state.isEquipment == 'phone' }">
    <!-- 面包屑 -->
    <div class="mainWidth">
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/" v-if="$route.path !== '/privateDetails'">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="mianrou" v-if="navshow" :class="{ review: $route.query.mian === 'review' }">{{
              mianone }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="mianroutwo" v-if="twoshow">{{
              miantwo
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="container" v-show="xqing_t">
        <el-row type="flex" justify="center">
          <el-col>
            <div class="content">
              <div class="video_play">
                <div class="title">
                  <p>{{ xqing_t }}</p>
                </div>
                <div class="source">
                  <p>{{ $ModuleDate(RECORD_TIME) }}</p>
                  <p v-if="xqing_l">来源：{{ xqing_l }}</p>

                  <hr class="cut_rule" />
                </div>

                <div class="playWrap">
                  <div class="share_box" v-if="this.$store.state.isEquipment == 'pc' &&
                    $route.path !== '/privateDetails'
                    ">
                    <div class="wxgzh">
                      <div class="wx_img">
                        <img src="@/assets/image/zixun/sk_wx_gzh.png" alt="" />
                      </div>
                      <p>微信公众号</p>
                    </div>
                    <div class="list">
                      <div class="sharebox-title">— <span>分享</span> —</div>
                      <div class="share xiner" @mouseover="getQRCode()">
                        <img src="@/assets/image/zixun/sk_ico_wx.png" alt="" class="icon" />
                        <img src="@/assets/image/zixun/sk_ico_wx_on.png" alt="" class="activeIcon" />
                        <!-- 二维码 -->
                        <div class="erweima">
                          <canvas id="QRCode_header"></canvas>
                          <!-- <canvas  v-lazy="qrCodeImg" alt=""> -->
                        </div>
                      </div>
                      <div class="share" @click="shareToMicroblog()">
                        <img src="@/assets/image/zixun/sina.png" alt="" class="icon" />
                        <img src="@/assets/image/zixun/sina_on.png" alt="" class="activeIcon" />
                      </div>
                      <div class="share" @click="copyUrl()">
                        <img src="@/assets/image/zixun/sk_ico_url.png" alt="" class="icon" />
                        <img src="@/assets/image/zixun/sk_ico_url_on.png" alt="" class="activeIcon" />
                        <div class="copyUrl" :class="{ copy_cur: copyed }">
                          链接已复制
                        </div>
                        <input type="text" class="link_url" style="
                            opacity: 0;
                            width: 0.063rem;
                            height: 0.063rem;
                            position: absolute;
                            bottom: 0;
                            z-index: -1;
                          " :value="shareUrl" />
                      </div>
                    </div>
                  </div>
                  <div class="play">
                    <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                      :options="playerOptions" @pause="onPlayerPause($event)" @play="onPlayerPlay($event)"
                      @ended="onPlayerEnded($event)" v-if="playerOptions.sources[0].src"></video-player>
                    <img src="http://www.cssn.cn/images/sk03_xszg_index14.png" alt="" @click="onPlayerClick"
                      v-if="!isPlay" />
                  </div>
                </div>

                <div class="introduce">
                  <p v-html="xqing_m"></p>
                </div>
              </div>
              <template v-if="$route.path !== '/privateDetails'">
                <VideoHeadline title="推荐视频" v-if="tuishow" />
                <Recommend @child-thr="onChildtwo" v-if="tuishow" :keywords="keywords" />
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import VideoHeadline from "@/components/ListHeadline.vue";
import Correlation from "./Correlation.vue";
import Recommend from "./Recommend.vue";
import api from "../../api/index";
import QRCode from "qrcode";
import axios from "axios";
export default {
  name: "",

  components: {
    VideoHeadline,
    Correlation,
    Recommend,
  },

  data() {
    return {
      isPlay: "",
      twoshow: false,
      mianroutwo: "",
      miantwo: "",
      navshow: true,
      mianone: "",
      mianrou: "",
      pasa: "",
      qrUrl: "",
      show: false,
      tuishow: true,
      // tuishow:true,
      qrCodeImg: "",
      tuishi: "",
      shareUrl: location.href,
      xqing_t: "", //标题
      RECORD_TIME: "", //时间
      xqing_m: "", //简介
      xqing_l: "", //来源
      playerOptions: {
        controls: true, //控件显示隐藏
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      copyed: false,
      keywords: '',
      menid: "",
      libid: "",
      childrenVideos: [],//视频组中的视频列表
    };
  },
  watch: {
    // sendText(newVal){
    //   this.VList = newVal
    // }
    "$store.state.details": {
      handler: function (n, o) {
        // console.log("n", o)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    console.log(this.$route);
    this.shareConfig();
    // 详情页
    api
      .post(
        `v2/spc/detail/detailByPubId.do?pubId=${this.$route.query.qing}&siteId=2`
      )
      .then((res) => {
        this.xqing_m = res.data.resourceDetail.DESCRIPTION; //简介
        this.xqing_t = res.data.resourceDetail.docName; //标题
        this.RECORD_TIME = res.data.resourceDetail.RECORD_TIME; //时间
        this.xqing_l = res.data.resourceDetail.PRORESOURCE; //来源
        this.keywords = res.data.resourceDetail.KEYWORDS;
        this.libid = res.data.resourceDetail.SYS_DOCLIBID;
        this.menid = res.data.resourceDetail.SYS_DOCUMENTID
        if (res.data.indexDetail.pub_content_type == "VIDEO-MEDIA") {
          //如果是单视频
          // console.log('是单条视频');
          this.show = false;
          this.getPortalResource(res.data.resourceDetail.doclibId, res.data.resourceDetail.docId);
        } else if (res.data.indexDetail.pub_content_type == "GROUP_VIDEO") {
          //如果是视频组
          api
            .get(
              `pub/docs/${res.data.resourceDetail.SYS_DOCLIBID}/${res.data.resourceDetail.SYS_DOCUMENTID}/getGroupMembers`
            )
            .then((res) => {
              this.childrenVideos = res;
              let item = res[this.$route.query.i]
              var doc = item.docID;
              var docLib = item.docLibID;
              this.RECORD_TIME = item.doc.created;
              this.xqing_t = item.doc.topic; //标题
              this.xqing_m = item.DESCRIPTION;
              this.getPortalResource(docLib, doc);
            });
          // console.log('我是组');
          this.show = true;
        }
        document.title=this.xqing_t
        // console.log('xx..',res.data.indexDetail.pub_content_type);
        // res.data.resourceDetail.KEYWORDS
      });
    // 面包屑
    if (this.$route.query.mian == "review") {
      this.mianone = "待审稿";
      this.mianrou = "";
    } else if (this.$route.query.mian == "navzi") {
      this.mianone = "资讯";
      this.mianrou = "/zixun";
    } else if (
      this.$route.query.mian == "navbk" ||
      this.$route.query.mian == "shou_bk"
    ) {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "我说";
      this.mianroutwo = "/bksjList?id=47";
    } else if (
      this.$route.query.mian == "navbks" ||
      this.$route.query.mian == "shou_bkr"
    ) {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "融合报道";
      this.mianroutwo = "/bksjList?id=48";
    } else if (
      this.$route.query.end == "共和国学人" ||
      this.$route.query.mian == "navxue" ||
      this.$route.query.mian == "shx0"
    ) {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "共和国学人";
      this.mianroutwo = "/xuerenlist?id=86";
    } else if (
      this.$route.query.end == "学术百家" ||
      this.$route.query.mian == "navxue2" ||
      this.$route.query.mian == "shx1"
    ) {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "学术百家";
      this.mianroutwo = "/xuerenlist?id=87";
    } else if (
      this.$route.query.end == "大家印象" ||
      this.$route.query.mian == "navxue3" ||
      this.$route.query.mian == "shx2"
    ) {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "大家印象";
      this.mianroutwo = "/xuerenlist?id=72";
    } else if (this.$route.query.mian == "navfang") {
      this.mianone = "访谈";
      this.mianrou = "/fangtan";
    } else if (this.$route.query.mian == "navwo") {
      this.mianone = "我说";
      this.mianrou = "/woshuo";
    } else if (
      this.$route.query.mianfu == "成果" ||
      this.$route.query.mian == "shou_cheng"
    ) {
      this.mianone = "成果";
      this.mianrou = "/homeList?id=66";
    } else if (this.$route.query.mianfu == "讲坛") {
      this.mianone = "讲坛";
      this.mianrou = "/homeList?id=67";
    } else if (this.$route.query.mianfu == "资讯") {
      this.mianone = this.$route.query.mianfu;
      this.mianrou = "/homeList?id=50";
      this.mianrou = "/zixun";
    } else if (this.$route.query.mianfu == "访谈") {
      this.mianone = this.$route.query.mianfu;
      this.mianrou = "/homeList?id=51";
    } else if (this.$route.query.mianfu == "纪实") {
      //首页纪实
      this.mianone = this.$route.query.mianfu;
      this.mianrou = "/homeList?id=68";
    } else if (this.$route.query.mianfu == "好书") {
      //首页好书
      this.mianone = this.$route.query.mianfu;
      this.mianrou = "/homeList?id=69";
    } else if (this.$route.query.bkhead == "融合报道") {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "融合报道";
      this.mianroutwo = "/bksjList?id=48";
    } else if (this.$route.query.bkhead == "我说") {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "我说";
      this.mianroutwo = "/bksjList?id=47";
    } else if (this.$route.query.bkhead == "视评") {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "视评";
      this.mianroutwo = "/bksjList?id=49";
    } else if (this.$route.query.mian == "shou_fang") {
      //首页访谈
      console.log("走这里");
      this.mianone = "访谈";
      this.mianrou = "/fangtan";
    } else if (this.$route.query.mian == "shou_ji") {
      //首页纪实
      this.mianone = "纪实";
      this.mianrou = "/homeList?id=68";
    } else if (this.$route.query.mian == "shou_zi") {
      this.mianone = "资讯";
      this.mianrou = "/zixun";
    } else if (this.$route.query.mian == "shi") {
      this.mianone = "报刊视界";
      this.mianrou = "/bksj";
      this.twoshow = true;
      this.miantwo = "视评";
      this.mianroutwo = "/bksjList?id=49";
    } else if (this.$route.query.mian == "我说") {
      this.mianone = "我说";
      this.mianrou = "/woshuo";
    } else if (this.$route.query.mian == "shou_zhong") {
      this.navshow = false;
    } else if (
      this.$route.query.mian == "shou_jiang" ||
      this.$route.query.mian == "pho讲坛"
    ) {
      this.mianone = "讲坛";
      this.mianrou = "/homeList?id=67";
    } else if (
      this.$route.query.mian == "shou_shu" ||
      this.$route.query.mian == "pho好书"
    ) {
      this.mianone = "好书";
      this.mianrou = "/homeList?id=69";
    } else if (this.$route.query.mian == "nzhi") {
      this.mianone = "直播";
      this.mianrou = "/zhibo";
    } else if (this.$route.query.tui == "pul_t") {
      this.navshow = false;
    } else if (this.$route.query.mian == "inp_jian") {
      this.mianone = "检索";
      this.mianrou = `/jiansuo?inp_value=${this.$route.query.retrieval}`;
    } else if (this.$route.query.mian == "pho资讯") {
      this.mianone = "资讯";
      this.mianrou = "/zixun";
    } else if (this.$route.query.mian == "pho访谈") {
      this.mianone = "访谈";
      this.mianrou = "/homeList?id=51";
    } else if (this.$route.query.mian == "pho共和国学人") {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "共和国学人";
      this.mianroutwo = "/xuerenlist?id=86";
    } else if (this.$route.query.mian == "pho大家印象") {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "大家印象";
      this.mianroutwo = "/xuerenlist?id=72";
    } else if (this.$route.query.mian == "pho学术百家") {
      this.mianone = "学人";
      this.mianrou = "/xueren";
      this.twoshow = true;
      this.miantwo = "学术百家";
      this.mianroutwo = "/xuerenlist?id=87";
    } else if (this.$route.query.mian == "phozi") {
      this.mianone = "资讯";
      this.mianrou = "/zixun";
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    getPubDetail(doclibId, docID) {
      api
        .get(
          `spc/prodb/pubDetail.do?doclibId=${doclibId}&docID=${docID}&siteId=2`,
          {}
        ) //单条详情数据
        .then((res) => {
          console.log(res);
          this.RECORD_TIME = res.RECORD_TIME;
        });
    },
    getPortalResource(doclibId, docId) {
      api
        .get(
          `spc/prodb/getPortalResource.do?doclibId=${doclibId}&docID=${docId}`,
          {}
        ) //单条详情数据
        .then((res) => {
          // console.log('hahahah', res);
          this.playerOptions.poster = res.data.cover; //视频封面
          this.playerOptions.sources[0].src = res.data.path; //视频
        });
      this.getPubDetail(doclibId, docId);
    },
    shareConfig() {
      const ua = window.navigator.userAgent.toLowerCase();
      let wx = null;
      let that = this;
      if (ua.indexOf("micromessenger") > -1) {
        wx = import("weixin-js-sdk");
        wx.then((res) => {
          wx = res;
          let uri = "";
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          if (isAndroid) {
            uri = location.href;
          }
          if (isIOS) {
            uri = location.href.split("#")[0];
          }
          axios
            .get("https://cssn.cn/wx-api/config/h5/share-init", {
              //接口地址为测试服务器地址，后期需替换成正式地址
              params: { url: uri },
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
            .then(function (response) {
              console.log(response);
              response = response.data;
              console.log(that.xqing_t);
              wx.config({
                debug: false,
                appId: response.data.appId,
                timestamp: response.data.timestamp,
                nonceStr: response.data.nonceStr,
                signature: response.data.signature,
                jsApiList: [
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ],
              });
              wx.ready(function () {
                console.log(that.xqing_t);
                wx.updateAppMessageShareData({
                  title: that.xqing_t, //自定义分享标题
                  desc: "中国社会科学网", //自定义分享描述
                  link: location.href, //分享地址
                  imgUrl:
                    "http://v.cssn.cn/prodb/api/apacheView/getCoverImage/29/2419807.do?type=middle&t=1687708869024", //自定义分享缩略图（注：需要填写图片网络地址，本地图片无效）
                  success: function () {
                    console.log("分享设置成功");
                  },
                });
                wx.updateTimelineShareData({
                  title: that.xqing_t, //自定义分享标题
                  link: location.href, //分享地址
                  imgUrl:
                    "http://v.cssn.cn/prodb/api/apacheView/getCoverImage/29/2419807.do?type=middle&t=1687708869024", //自定义分享缩略图（注：需要填写图片网络地址，本地图片无效）
                  success: function () {
                    console.log("朋友圈分享设置成功");
                  },
                });
              });
              wx.error(function (res) {
                console.log(res.errMsg);
              });
            });
        });
      }
    },
    onPlayerPlay(player) {
      this.videoPlayState = true;
    },
    onPlayerPause($event) {
      var shikong = document.getElementsByClassName("vjs-control-bar")[0]; // 视频控件
      shikong.style.visibility = "hidden";
      this.isPlay = false;
    },
    onPlayerPlay($event) {
      var shikong = document.getElementsByClassName("vjs-control-bar")[0]; // 视频控件
      shikong.style.visibility = "visible";
      this.isPlay = true;
    },
    onPlayerEnded($event) { },
    onPlayerClick() {
      if (this.isPlay) {
        this.player.pause();
      } else {
        this.player.play();
      }
    },
    onChildEvent(n) {
      this.getPortalResource(n.docLibID, n.docID);
      this.RECORD_TIME = n.doc.created;
      this.xqing_t = n.doc.topic; //标题
      this.xqing_m = n.DESCRIPTION;
    },
    onChildtwo(dat) {
      this.tuishow = dat;
      // console.log('dddat', dat);
    },
    // 分享到微博
    shareToMicroblog() {
      //跳转地址
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
        encodeURIComponent(this.shareUrl)
      );
    },
    // 分享到qq空间
    shareToQQRom() {
      //跳转地址
      window.open(
        "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
        encodeURIComponent(this.shareUrl)
      );
    },
    copyUrl() {
      document.getElementsByClassName("link_url")[0].select();
      document.execCommand("copy");
      this.copyed = true;
      setTimeout(() => {
        this.copyed = false;
      }, 3000);
    },
    //分享到微信
    shareToWeChat() {
      localStorage.setItem("shareUrl", this.shareUrl); // 存储当前页面地址，前端页面直接根据URL链接生成二维码
      // let url = location.href.split("#")[0]; // 注意：#/Share/toWechat: 自己定义的路由
      // window.open(url);
    },

    getQRCode() {
      //生成的二维码为URL地址js
      this.qrUrl = this.shareUrl;
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 150, //宽
        height: 150, //高
        // text: "http://www.baidu.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById("QRCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          // this.$message.error("二维码加载失败");
        }
      });
    },
  },
  destroyed() {
    document.title = '社科视频'
  },
};
</script>

<style lang='scss' scoped>
.Details {
  background: #f9f9f9;
  padding-bottom: 32px;

  .crumbs {
    padding-top: 20px;
    color: #999999;
  }

  .container {
    .content {
      margin-top: 20px;
      background: #ffffff;
      padding: 44px 48px 16px 48px;

      .video_play {
        margin-bottom: 32px;
        // width: 100%;

        .title {
          text-align: center;

          p {
            font-size: 38px;
            font-weight: bold;
            color: #212121;
            line-height: 56px;
          }
        }

        .source {
          // line-height: 35.008px;
          // border-bottom: .063rem solid #cad5e2;
          margin-top: 32px;

          p {
            margin-right: 11.2px;
            color: #212121;
            line-height: 40px;
            margin-top: 8px;
            display: inline-block;
            height: 40px;
          }

          // p {
          //   margin-right: 11.2px;
          //   color: #212121;
          //   line-height: 40px;
          //   margin-top: 8px;
          //   display: inline-block;
          //   height: 40px;
          // }

          .share {
            // float: right;
            display: inline-block;
            width: 32px;
            height: 32px;
            cursor: pointer;
            margin-right: 11.2px;
          }

          .xiner:hover .erweima {
            display: block;
          }

          .xiner {
            position: relative;

            .erweima {
              z-index: 10;

              span {
                font-style: normal;
                margin: 0;
                padding: 0;
                font-size: 12px;
              }

              padding: 10px 10px 0.063rem 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-content: center;
              // img{
              //   width: 100%!important;
              //   height: 100%!important;
              // }
              // canvas{
              //   height: 100%;
              //   height: 100%;
              // }
              position: absolute;
              left: -42px;
              top: 50px;
              width: 100px;
              height: 130px;
              background-color: red;
              display: none;
              border: 0.063rem solid #ccc;
              text-align: center;
              background: #fff;
              border-radius: 0.313rem;
            }
          }

          .cut_rule {
            width: 100%;
            border: 0.063rem solid #cad5e2;
          }
        }

        .play {
          width: 75%;
          margin: 32px auto 32px auto;
          position: relative;
          box-shadow: 0.313rem 0.313rem 0.5rem #888888;

          img {
            width: 15%;
            // height: 15%;
            position: absolute;
            bottom: 3%;
            left: 2%;
            cursor: pointer;
            opacity: 0.7;
          }
        }

        .introduce {
          margin-top: 48px;

          p {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            text-indent: 2em;
            text-align: justify;
          }
        }
      }
    }
  }

  .review {
    background-color: #2181b0;
    line-height: 30px;
    padding: 0 10px;

    /deep/ .el-breadcrumb__inner {
      color: #fff;
    }
  }
}

.Details.phone {
  .crumbs {
    padding-left: 1rem;
  }
}
.playWrap{
  display: flex;
}
.share_box {
  width: 100px;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 50px 0;
  border-right: 1px #eeeeee solid;

  .wxgzh {
    width: 80px;
    border: 1px #dddddd solid;
    box-sizing: border-box;
    margin-top: 30px;
  }

  .wxgzh p {
    background: #dddddd;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
  }

  .wxgzh .wx_img {
    padding: 7px 0;
  }

  .wxgzh .wx_img img {
    width: 65px;
    height: 65px;
    display: block;
    margin: 0 auto;
  }

  .list {
    width: 80px;
    margin-top: 30px;

    .share {
      position: relative;
      transition: 0.3s;
      width: 41px;
      margin: 15px auto 0;
    }

    .erweima {
      display: none;
      position: absolute;
      top: 55px;
      left: 20px;
      padding: 10px;
      line-height: 36px;
      border-radius: 7px;
      background: #fff;
      box-shadow: 0px 2px 8px #999;
      color: #999;
      opacity: 1;
      z-index: 999;
      font-size: 16px;
      width: 150px;
      text-align: center;
      transform: translateX(-50%);

      canvas {
        display: block;
      }
    }

    .icon,
    .activeIcon {
      width: 100%;
      display: block;
    }

    .activeIcon {
      display: none;
    }

    .share:hover {
      .erweima {
        display: block;
      }

      .activeIcon {
        display: block;
      }

      .icon {
        display: none;
      }
    }
  }

  .sharebox-title {
    color: #666;
    font-size: 14px;
    text-align: center;
  }

  .copyUrl {
    position: absolute;
    top: 55px;
    right: -15px;
    padding: 0px 10px;
    line-height: 36px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0px 2px 8px #999;
    color: #999;
    opacity: 1;
    visibility: hidden;
    z-index: 999;
    font-size: 14px;
    width: 86px;
    text-align: center;
  }

  .copyUrl.copy_cur {
    animation: copySuccess 3s forwards;
  }

  @keyframes copySuccess {
    0% {
      visibility: visible;
    }

    30% {
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.el-breadcrumb__inner.is-link {
  color: #999999;
}

.vjs-custom-skin>.video-js .vjs-big-play-button {
  top: 79%;
  left: 40%;
  width: 4.375rem !important;
  height: 4.375rem !important;
  border-radius: 50% !important;
  margin-left: -6.5em !important;
  margin-top: -1em;
  // display: none;
  visibility: hidden;
}

.vjs-icon-placeholder:before {
  position: absolute;
  top: -0.063rem;
  left: 0.063rem;
  width: 100%;
  height: 100%;
}

.vjs-control-bar {
  // display: none!important;
}

// font-weight: 400;
//     color: #606266;
</style>